import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-west-covina-california.png'
import image0 from "../../images/cities/scale-model-of-public-art-\"cougar-park-mural\"-in-west-covina-california.png"
import image1 from "../../images/cities/scale-model-of-public-art-\"a-passage-through-memory\"-in-west-covina-california.png"
import image2 from "../../images/cities/scale-model-of-public-art-\"the-stop\"-in-west-covina-california.png"
import image3 from "../../images/cities/scale-model-of-public-art-\"azusa-horticultural-paradise\"-in-west-covina-california.png"
import image4 from "../../images/cities/scale-model-of-美国洛杉矶旅游us-perfect-travel-in-west-covina-california.png"
import image5 from "../../images/cities/scale-model-of-public-art-\"the-location\"-in-west-covina-california.png"
import image6 from "../../images/cities/scale-model-of-public-art-\"airplane-mural\"-in-west-covina-california.png"
import image7 from "../../images/cities/scale-model-of-arroyo-south-in-west-covina-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='West Covina'
            state='California'
            image={image}
            lat='34.0559'
            lon='-117.9099'
            attractions={ [{"name": "Public Art \"Cougar Park Mural\"", "vicinity": "159 W Puente St, Covina", "types": ["point_of_interest", "establishment"], "lat": 34.081032, "lng": -117.89195899999999}, {"name": "Public Art \"A Passage Through Memory\"", "vicinity": "Station Platform, E Santa Fe Ave, Azusa", "types": ["point_of_interest", "establishment"], "lat": 34.1357461, "lng": -117.9072703}, {"name": "Public Art \"The Stop\"", "vicinity": "706 E Olive Ave, Monrovia", "types": ["point_of_interest", "establishment"], "lat": 34.1446976, "lng": -118.0007306}, {"name": "Public Art \"Azusa Horticultural Paradise\"", "vicinity": "Station Platform, East Promenade, Azusa", "types": ["point_of_interest", "establishment"], "lat": 34.1368595, "lng": -117.89144399999998}, {"name": "\u7f8e\u56fd\u6d1b\u6749\u77f6\u65c5\u6e38US Perfect Travel", "vicinity": "4778 Peck Rd, El Monte", "types": ["point_of_interest", "establishment"], "lat": 34.0900178, "lng": -118.01441520000003}, {"name": "Public Art \"The Location\"", "vicinity": "S Myrtle Ave, Monrovia", "types": ["point_of_interest", "establishment"], "lat": 34.14762690000001, "lng": -118.0008947}, {"name": "Public Art \"Airplane Mural\"", "vicinity": "Monrovia", "types": ["point_of_interest", "establishment"], "lat": 34.1476019, "lng": -118.0015823}, {"name": "Arroyo South", "vicinity": "Azusa", "types": ["park", "point_of_interest", "establishment"], "lat": 34.141997, "lng": -117.89418089999998}] }
            attractionImages={ {"Public Art \"Cougar Park Mural\"":image0,"Public Art \"A Passage Through Memory\"":image1,"Public Art \"The Stop\"":image2,"Public Art \"Azusa Horticultural Paradise\"":image3,"美国洛杉矶旅游US Perfect Travel":image4,"Public Art \"The Location\"":image5,"Public Art \"Airplane Mural\"":image6,"Arroyo South":image7,} }
       />);
  }
}